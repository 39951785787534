<template>
    <div class="sim-cart-drawer-list">
        <!--  CART ITEMS  -->
        <template v-if="areItemsLoading">
            <template v-for="i in cart?.uniqueAmount" :key="i">
                <hr class="my-6">

                <BaseUiSkeleton class="h-[7rem]" color="gray" type="shine" />
            </template>
        </template>

        <template v-else>
            <template v-for="cartItem in items" :key="cartItem.id!">
                <hr class="my-6">

                <CartItem
                    :cart-item="cartItem"
                    class="sim-cart-item-card--cart-item"
                    :class="{
                        'sim-cart-item-card--highlighted': cartItem.id === highlightedCartItemId,
                    }"
                >
                    <template #actions>
                        <div class="flex items-center justify-end gap-2">

                            <!--  TODO: make smaller  -->
                            <UiCounter
                                counter-class="bg-primary-200"
                                class="mr-auto"
                                :value="cartItem.amount"
                                :min-value="0"
                                :max-value="cartItem.stockState ?? 0"
                                :on-input="(value: number) => onCounterInput(cartItem.id!, value)"
                            />

                            <UiButton
                                variant="plain"
                                color="primary"
                                class="sim-menu-btn"
                                :aria-label="$t('accessibility.remove_item_from_cart')"
                                @click="removeItem(cartItem.id)"
                            >
                                <IconTrash size="sm" aria-hidden />
                            </UiButton>
                        </div>
                    </template>
                </CartItem>
            </template>
        </template>

        <hr class="my-6">

        <!--  SUMMARY  -->
        <div class="sim-cart-drawer-list__total">
            <CartTotal type="drawer" />
        </div>

        <LazyModalRemoveCartItem
            v-if="wasModalMounted"
            v-model="isModalOpen"
            :on-submit="confirmRemoveItem"
        />

    </div>
</template>

<script lang="ts" setup>

const {
    highlightedCartItemId = null,
} = defineProps<{
    highlightedCartItemId?: number | null
}>()

const { removeItem, changeItemQuantity, items, areItemsLoading, cart } = useCart()
const { is: isModalOpen, was: wasModalMounted } = useLazyMount()
const currentCartItemId = ref<number | null>(null)

const openRemoveModal = (cartItemId: number) => {
    currentCartItemId.value = cartItemId
    isModalOpen.value = true
}

const confirmRemoveItem = () => {
    if (!currentCartItemId.value) return
    removeItem(currentCartItemId.value)
    isModalOpen.value = false
}
const onCounterInput = (id: number, value: number) => {
    if (value === 0) {
        openRemoveModal(id)
        return false
    }
    changeItemQuantity(id, value)
    return true
}


</script>

<style lang="scss" scoped>
.sim-cart-drawer-list {
    color: $sim-c-primary-800;
}

.sim-cart-drawer-list__total {
    margin-top: 1.5rem;
}

.sim-cart-item-card--highlighted {
    animation: appear 500ms $sim-timing;
    animation-fill-mode: both;
    animation-delay: 300ms;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        opacity: 1;
    }

}

</style>
