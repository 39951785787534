<template>
    <BaseSideDrawer
        v-model="isDrawerOpen"
        size="sm"
        auto-close-on="route-change"
        @closed="$emit('closed')"
    >
        <!--  HEADER  -->
        <template #header>
            <UiModalHeader>
                {{ $t('cart.cart') }}

                <UiCountIndicator v-if="cart?.hasItems()"
                                  :count="cart.totalAmount!"
                                  class="-translate-y-1/2"
                />
            </UiModalHeader>
        </template>


        <!--  NO ITEMS MESSAGE  -->
        <p v-if="showNoItemsMessage" class="sim-h5 mx-auto my-20 max-w-sm text-center">
            {{ $t('cart.no_items') }}
        </p>

        <!--  CART ITEMS  -->
        <template v-else>
            <!--  FREE SHIPPING  -->
            <CartFreeShippingProgress
                v-if="freeShippingPrice?.value"
                class="py-6"
                :current="getPriceValue(cart?.discountedTaxedItemsPrice) ?? 0"
                :target="freeShippingPrice.value"
                :currency-symbol="currencySymbol"
            />

            <!--  CART ITEMS  -->
            <CartDrawerList
                :highlighted-cart-item-id="highlightedCartItemId"
            />
        </template>

        <!--  BUTTON  -->
        <UiButton
            color="primary"
            size="giant"
            class="mb-24 mt-6 w-full"
            :to="localePath('cart')"
        >
            {{ $t('cart.go_to_cart') }}
        </UiButton>
    </BaseSideDrawer>
</template>

<script lang="ts" setup>
import { getPriceValue } from '@sim-api-utils/localization'

const {
    highlightedCartItemId = null,
} = defineProps<{
    highlightedCartItemId?: number | null
}>()

const emit = defineEmits<{
    closed: []
}>()

const { freeShippingPrice, currencySymbol } = useProperties()

const localePath = useLocalePath()
const { cart, fetchItems } = useCart()

const isDrawerOpen = defineModel<boolean>()
watch(isDrawerOpen, async (value) => {
    await fetchItems()
}, { immediate: true })

const showNoItemsMessage = computed<boolean>(() => !cart.value?.hasItems())

</script>

<style lang="scss" scoped>

</style>
